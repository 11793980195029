export var PromoEvent;
(function (PromoEvent) {
    PromoEvent["PromoClick"] = "promoClick";
    PromoEvent["PromoView"] = "promoView";
    PromoEvent["PromoClose"] = "promoClose";
})(PromoEvent || (PromoEvent = {}));
export var ProductEvent;
(function (ProductEvent) {
    ProductEvent["View"] = "productView";
    ProductEvent["Click"] = "productClick";
    ProductEvent["DetailView"] = "productDetailView";
})(ProductEvent || (ProductEvent = {}));
export var EventAction;
(function (EventAction) {
    EventAction["Click"] = "click";
    EventAction["Close"] = "close";
    EventAction["View"] = "view";
    EventAction["Success"] = "success";
    EventAction["Login"] = "login";
    EventAction["Signup"] = "signup";
    EventAction["Error"] = "error";
})(EventAction || (EventAction = {}));
export var EventCategory;
(function (EventCategory) {
    EventCategory["Product"] = "product";
    EventCategory["Promotion"] = "promotion";
    EventCategory["User"] = "user";
    EventCategory["Link"] = "link";
    EventCategory["Newsletter"] = "newsletter";
})(EventCategory || (EventCategory = {}));
export var CartEvent;
(function (CartEvent) {
    CartEvent["AddToCart"] = "addToCart";
    CartEvent["RemoveFromCart"] = "removeFromCart";
})(CartEvent || (CartEvent = {}));
export var NewsletterSignupEvent;
(function (NewsletterSignupEvent) {
    NewsletterSignupEvent["NewsLetterSignUpClick"] = "newsLetterSignUpClick";
    NewsletterSignupEvent["NewsLetterSignUpSuccess"] = "newsLetterSignUpSuccess";
})(NewsletterSignupEvent || (NewsletterSignupEvent = {}));
export var NewsletterSignupName;
(function (NewsletterSignupName) {
    NewsletterSignupName["NewsletterOverlay"] = "NewsletterOverlay";
    NewsletterSignupName["NewsletterSignUp"] = "NewsletterSignUp";
    NewsletterSignupName["NewsletterPopUpFullScreen"] = "NewsletterPopUpFullScreen";
    NewsletterSignupName["NewsletterPopUpMinimized"] = "NewsletterPopUpMinimized";
    NewsletterSignupName["NewsletterSubscriptionCheckBox"] = "NewsletterSubscriptionCheckBox";
})(NewsletterSignupName || (NewsletterSignupName = {}));
export var SignupEvent;
(function (SignupEvent) {
    SignupEvent["Login"] = "loginEvent";
    SignupEvent["LoginFailed"] = "loginFailed";
    SignupEvent["Register"] = "signUpEvent";
    SignupEvent["RegisterFailed"] = "signUpFailed";
})(SignupEvent || (SignupEvent = {}));
export var WishlistButtonEvent;
(function (WishlistButtonEvent) {
    WishlistButtonEvent["RemoveFromWishlist"] = "removeFromWishlist";
    WishlistButtonEvent["AddToWishlist"] = "addToWishlist";
})(WishlistButtonEvent || (WishlistButtonEvent = {}));
export var MyAccountEvent;
(function (MyAccountEvent) {
    MyAccountEvent["NavigationClick"] = "navigation-grid_click";
    MyAccountEvent["SubscriptionClick"] = "subscriptions-list_click";
    MyAccountEvent["ProfileUpdate"] = "myAccountProfile";
})(MyAccountEvent || (MyAccountEvent = {}));
export var MyAccountElement;
(function (MyAccountElement) {
    MyAccountElement["NavigationCard"] = "card";
})(MyAccountElement || (MyAccountElement = {}));
export var ErrorEvent;
(function (ErrorEvent) {
    ErrorEvent["ErrorView"] = "errorView";
})(ErrorEvent || (ErrorEvent = {}));
export var ErrorEventMessage;
(function (ErrorEventMessage) {
    ErrorEventMessage["NotSellable"] = "not sellable";
    ErrorEventMessage["GenericMessage"] = "generic message";
})(ErrorEventMessage || (ErrorEventMessage = {}));
export var DisclaimerType;
(function (DisclaimerType) {
    DisclaimerType["Rating"] = "rating";
})(DisclaimerType || (DisclaimerType = {}));

import mitt from 'mitt';
var createEmitter = function (latestData) {
    if (latestData === void 0) { latestData = {}; }
    var emitter = mitt();
    emitter.on('*', function (type, data) {
        latestData[type] = data;
    });
    emitter.latestEmitted = function (type) { return latestData[type]; };
    return emitter;
};
/**
 * Returns global context or an empty object
 */
var getContext = function () {
    return typeof window !== 'undefined' && window.window === window
        ? window
        : typeof global !== 'undefined' && global.global === global
            ? global
            : {};
};
/**
 * Creates an instance of event bus with
 * the shared, global emitter
 */
var EventBus = /** @class */ (function () {
    function EventBus() {
        this.context = getContext();
        if (!this.context[EventBus.latestDataNamespace]) {
            this.context[EventBus.latestDataNamespace] = {};
        }
        this.latestData = this.context[EventBus.latestDataNamespace];
        if (!this.context[EventBus.namespace]) {
            this.context[EventBus.namespace] = createEmitter(this.latestData);
        }
        this.emitter = this.context[EventBus.namespace];
    }
    /**
     * Subscribe handler to the event
     */
    EventBus.prototype.on = function (name, handler) {
        this.emitter.on(name, handler);
    };
    /**
     * Unsubscribe handler from the event
     */
    EventBus.prototype.off = function (name, handler) {
        this.emitter.off(name, handler);
    };
    /**
     * Publish event
     */
    EventBus.prototype.emit = function (name, data) {
        this.emitter.emit(name, data);
    };
    /**
     * Retrieve latest emitted value
     */
    EventBus.prototype.latestEmitted = function (name) {
        return this.emitter.latestEmitted(name);
    };
    // create namespace in global symbol registry
    EventBus.namespace = Symbol.for('EVENT_BUS_GLOBAL_NAMESPACE');
    EventBus.latestDataNamespace = Symbol.for('EVENT_BUS_LATEST_DATA');
    return EventBus;
}());
export default EventBus;

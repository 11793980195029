import { Cookie as CookieEnum } from 'corgi-types';
import Cookie from 'universal-cookie';
var CookieStorage = /** @class */ (function () {
    /**
     * Creates an instance of universal cookie storage.
     *
     * @constructor
     */
    function CookieStorage() {
        this.cookie = null;
        this.init();
    }
    CookieStorage.normalizeNumber = function (value) {
        value = Number(value);
        return isNaN(value) ? 0 : Math.max(0, value);
    };
    CookieStorage.normalizeString = function (value) {
        return typeof value === 'string' ? value : '';
    };
    CookieStorage.prototype.init = function () {
        this.cookie = new Cookie();
    };
    CookieStorage.prototype.__get = function (key) {
        return this.cookie.get(key);
    };
    CookieStorage.prototype.__set = function (key, value, options) {
        return this.cookie.set(key, value, options);
    };
    CookieStorage.prototype.getWishlistCount = function () {
        return CookieStorage.normalizeNumber(this.__get(CookieEnum.WishlistItemsCount));
    };
    CookieStorage.prototype.setWishlistCount = function (count, options) {
        if (options === void 0) { options = {}; }
        this.__set(CookieEnum.WishlistItemsCount, CookieStorage.normalizeNumber(count), options);
    };
    CookieStorage.prototype.getCartCount = function () {
        return CookieStorage.normalizeNumber(this.__get(CookieEnum.CartItemsCount));
    };
    CookieStorage.prototype.getZipCode = function () {
        var _a;
        return (_a = this.__get(CookieEnum.ZipCode)) !== null && _a !== void 0 ? _a : null;
    };
    CookieStorage.prototype.setZipCode = function (zipCode) {
        if (zipCode) {
            var MAX_AGE = 365 * 24 * 60 * 60;
            this.__set(CookieEnum.ZipCode, zipCode, { path: '/', maxAge: MAX_AGE, secure: true });
        }
    };
    return CookieStorage;
}());
export { CookieStorage };
export default new CookieStorage();

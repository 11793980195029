export var EventBusEvent;
(function (EventBusEvent) {
    EventBusEvent["ToggleSideNav"] = "TOGGLE_SIDE_NAV";
    EventBusEvent["WishlistCounterUpdate"] = "update.wishlist:counter";
    EventBusEvent["CartCounterUpdate"] = "update.header:cart";
    EventBusEvent["AddAlerts"] = "ADD_ALERT";
    EventBusEvent["ShowEmptyCart"] = "SHOW_EMPTY_CART";
    EventBusEvent["ToggleDisclaimer"] = "TOGGLE_DISCLAIMER";
    EventBusEvent["ReclamationRequestListChange"] = "RECLAMATION_REQUEST_LIST_CHANGE";
    EventBusEvent["ReclamationDrawerToggle"] = "RECLAMATION_DRAWER_TOGGLE";
    EventBusEvent["ReclamationImageGalleryToggle"] = "RECLAMATION_IMAGE_GALLERY_TOGGLE";
    EventBusEvent["ReclamationUploadedPicturesStateChange"] = "RECLAMATION_UPLOADED_PICTURES_STATE_CHANGE";
    EventBusEvent["CheckoutButtonState"] = "CHECKOUT_BUTTON_STATE";
    EventBusEvent["CheckoutButtonStateTaxId"] = "CHECKOUT_BUTTON_STATE_TAX_ID";
    EventBusEvent["ToggleCategoriesDrawer"] = "TOGGLE_CATEGORIES_DRAWER";
    EventBusEvent["ToggleCategoryFiltersDrawer"] = "TOGGLE_CATEGORY_FILTERS_DRAWER";
    EventBusEvent["ToggleAlert"] = "TOGGLE_ALERT";
    EventBusEvent["ToggleCheckoutAlert"] = "TOGGLE_CHECKOUT_ALERT";
    EventBusEvent["OnCheckoutErrorMessage"] = "ON_CHECKOUT_ERROR_MESSAGE";
    EventBusEvent["ToggleSellerDrawer"] = "TOGGLE_SELLER_DRAWER";
    EventBusEvent["OpenModel3D"] = "OPEN_MODEL_3D";
    EventBusEvent["OpenSketchOverlay"] = "OPEN_SKETCH_OVERLAY";
    EventBusEvent["BottomDrawerUpdate"] = "BOTTOM_DRAWER_UPDATE";
    EventBusEvent["CartUpdate"] = "CART_SUMMARY_UPDATE";
    EventBusEvent["MyProfileAlert"] = "MY_PROFILE_ALERT";
    EventBusEvent["GiftCardsUpdate"] = "GIFT_CARDS_UPDATE";
    EventBusEvent["CartProductListUpdate"] = "CART_PRODUCT_LIST_UPDATE";
    EventBusEvent["TogglePaymentSelectionDrawer"] = "TOGGLE_PAYMENT_SELECTION_DRAWER";
    EventBusEvent["VoucherUpdate"] = "VOUCHER_UPDATE";
    EventBusEvent["ArticleShippingDataUpdate"] = "ARTICLE_SHIPPING_DATA_UPDATE";
    EventBusEvent["ToggleDrawerOverlay"] = "TOGGLE_DRAWER_OVERLAY";
})(EventBusEvent || (EventBusEvent = {}));
